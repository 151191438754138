<template>
  <div class="fscom-external-countdown-container" :class="{'right-rail' : model.rightRail}">
    <div class="cd-wrapper">
      <div class="cd-image">
        <div class="overlay" />
        <img :src="background">
      </div>
      <div class="cont">
        <img v-if="!model.rightRail" :src="model.logo" width="120" height="85">
        <span class="cd-text"> {{ model.countdownText }} </span>
        <div class="cd-display">
          <div v-for="data in countdownData" :key="data.label" class="countdown-time-display">
            <div class="time-value">
              {{ data.time }}
            </div>
            <div class="time-label">
              {{ data.label }}
            </div>
          </div>
        </div>
        <a :href="model.webUrl" class="cd-link"> {{ model.countdownLinkText }} </a>
      </div>
    </div>
  </div>
</template>

<script>
import { parseISO, differenceInMilliseconds } from 'date-fns';

export default {
  name: 'ExternalCountdown',
  props: {
    model: {
      default: null,
      type: Object,
      required: true
    }
  },
  data () {
    return {
      countdownData: {}
    };
  },
  computed: {
    background () {
      return this.model.rightRail ? this.model.rightRailImage : this.model.backgroundImage;
    }
  },
  mounted () {
    const date = this.model.date;
    this.countdownTime(date);
    this.interval = setInterval(() => {
      this.countdownTime(date);
    }, 1000);
  },
  methods: {
    countdownTime (startTime) {
      const result = [];
      const future = new Date(parseISO(startTime)).getTime();
      const now = new Date().getTime();
      const t = future - now;
      let days; let hours; let minutes; let seconds = 0;
      if (t >= 0) {
        days = Math.floor(t / (1000 * 60 * 60 * 24));
        hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        seconds = Math.floor((t % (1000 * 60)) / 1000);
      }
      result.push({
        label: 'Days',
        time: days > 0 ? days.toString().padStart(2, '0') : '00'
      });

      result.push({
        label: 'Hrs',
        time: hours > 0 ? hours.toString().padStart(2, '0') : '00'
      });

      result.push({
        label: 'Min',
        time: minutes > 0 ? minutes.toString().padStart(2, '0') : '00'
      });

      result.push({
        label: 'Sec',
        time: seconds > 0 ? seconds.toString().padStart(2, '0') : '00'
      });

      this.countdownData = result;

      if (differenceInMilliseconds(parseISO(startTime), new Date()) <= 0) {
        clearInterval(this.interval);
      }
    }
  }
};
</script>
<style scoped>

</style>
