import Vue from 'vue';
import ExternalCountdown from './index.vue';
// import Test from './test.vue';

export function initExternalCountdown () {
  const countdown = document.querySelectorAll('.fscom-external-countdown');
  if (countdown.length <= 0) {
    return;
  }
  countdown.forEach((c) => {
    if (c) {
      const isRightRail = c.getAttribute('data-isRightRail');
      const startDate = c.getAttribute('data-startdate');
      // eslint-disable-next-line no-new
      new Vue({
        el: c,
        // render: h => h('div', 'hello world')
        render: h => h(ExternalCountdown, {
          props: {
            model: {
              date: startDate,
              webUrl: 'https://www.foxsports.com/nfl/super-bowl',
              rightRail: isRightRail === 'true',
              countdownText: 'COUNTDOWN TO SB LVII KICKOFF',
              countdownLinkText: 'View Latest Super Bowl Updates',
              rightRailImage: 'https://b.fssta.com/sta/images/sb/SBLVII_RR_Countdown.png',
              backgroundImage: 'https://b.fssta.com/sta/images/sb/SR-AT&T-Stadium.png',
              logo: 'https://b.fssta.com/sta/images/sb/SB-Logo.png'
            }
          }
        })
      });
    }
  });
}
